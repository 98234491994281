<template>
  <div class="m-2">
    <arq-previews-page class="ml-4" />
    <h1>Consulta de Cartera</h1>
    <form
      v-show="items.length === 0"
      class="w-50 lg:w-25 mx-auto my-4"
      @submit.prevent="confirm()"
    >
      <div class="card p-2">
        <div
          v-for="(f, index) in form"
          :key="index"
        >
          <label :for="f.key">
            {{ f.label }}
          </label>
          <div v-if="types.includes(f.type)">
            <div v-if="f.key.includes('identification')">
              <b-form-input
                :id="f.key"
                v-model="data[f.key]"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
                @input="getContract(data[f.key])"
              />
            </div>
            <b-form-input
              v-else
              :id="f.key"
              v-model="data[f.key]"
              :name="f.key"
              :type="f.type"
              :required="f.required"
              :disabled="f.disabled"
            />
          </div>
          <div v-if="f.type === 'select-services'">
            <b-form-select
              :id="f.key"
              v-model.number="data[f.key]"
              value-field="idProducto"
              text-field="descservicio"
              :options="optionsServices"
            />
          </div>
          <div v-if="f.type === 'select-contract'">
            <div v-if="contratos.length > 0">
              <b-form-select
                v-model.number="data[f.key]"
                value-field="idProducto"
                text-field="descservicio"
                :options="contratos"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="data[f.key]"
                value-field="idProducto"
              />
            </div>
          </div>

          <b-form-file
            v-if="f.type === 'file'"
            :id="f.key"
            v-model="data[f.key]"
            :state="Boolean(data[f.key])"
            placeholder="Adjuntar documento..."
            drop-placeholder="Suelta el documento aqui..."
          />
          <div v-if="f.type === 'textarea'">
            <b-form-textarea
              :id="f.key"
              v-model="data[f.key]"
              rows="8"
            />
          </div>
        </div>
      </div>
      <b-button
        variant="primary mt-3"
        @click="confirm()"
      >
        Confirmar
      </b-button>
    </form>
    <div
      v-if="items.length > 0"
      class="col col-md-6 col-sm-2 mx-auto"
    >
      <b-card>
        <b-button
          variant="primary my-2"
          @click="items = []"
        >
          Consultar otro contrato
        </b-button>
        <div style="color:white">
          <div
            style="background-color: var(--color)"
            class="p-2 rounded"
          >
            <p
              class="ml-4 text-right"
              style="font-size: 16px"
            >
              <b>
                Total Deuda:
              </b>
              {{ total | toCurrency }}
            </p>
            <p
              class="ml-4 text-right"
              style="font-size:16px"
            >
              <b>
                Saldo Pendiente Factura Actual:
              </b>
              {{ totalBalance | toCurrency }}
            </p>
            <h5
              v-if="totalFinancing !== 0"
              style="font-size:16px"
              class="ml-4 text-white  text-right bold"
            >
              <b>Saldo Pendiente Financiación:</b>
              {{ totalFinancing | toCurrency }}
            </h5>
          </div>
        </div>
        <div
          v-if="detailsFinancing.length > 0"
          class="d-flex mr-4"
        >
          <h5 class="ml-4 mt-4">
            Detalle Factura Actual
          </h5>
        </div>
        <div>
          <ArqTable
            :head="itemsTh"
            :rows="items"
          >
            <template #CUENTAS_CON_SALDO="{ data }">
              <div
                style="text-align: right;"
                class="text-primary"
              >
                {{ data.value }}
              </div>
            </template>
            <template #TOTAL_PENDIENTE="{ data }">
              <div
                style="text-align: right;"
                class="text-primary"
              >
                {{ data.value | toCurrency }}
              </div>
            </template>
          </ArqTable>
          <!-- <b-table
            class="mt-2"
            responsive="sm"
            :items="items"
          >
            <template #cell(TotalPendiente)="data">
              <div class="text-right text-primary">
                {{ data.value | toCurrency }}
              </div>
            </template>
          </b-table> -->
        </div>
        <div
          v-if="detailsFinancing.length > 0"
          class="d-flex mr-4"
        >
          <h5 class="ml-4 mt-4">
            Detalle Financiación
          </h5>
        </div>
        <div v-if="detailsFinancing.length > 0">
          <ArqTable
            :head="detailsFinancingTh"
            :rows="detailsFinancing"
          >
            <template
              #Codigo_Financiacion="{ data }"
              class="float-right"
            >
              <span class="text-nowrap text-primary float-right">
                {{ data.value }}
              </span>
            </template>
            <template
              #Total="{ data }"
              class="float-right"
            >
              <span class="text-nowrap text-primary float-right">
                {{ data.value | toCurrency }}
              </span>
            </template>
          </ArqTable>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      currentPage: 0,
      items: [],
      itemsTh: ['NOMBRE SERVICIO', 'CUENTAS CON SALDO', 'TOTAL PENDIENTE'],
      detailsFinancing: [],
      detailsFinancingTh: ['Codigo Financiación', 'Total'],
      CodigoFinanciacióntotal: 0,
      totalFinancing: 0,
      totalBalance: 0,
      pageCount: 0,
      data: {},
      pdfBase64: '',
      process: 'descargar-duplicados',
      title: 'Descarga de Duplicados',
      procedure: '/debt',
      method: 'get',
      facturas: [],
      form: [
        {
          label: 'Contrato',
          key: 'idcontrato',
          type: 'select-contract',
          required: true,
        },
      ],
      types: [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color',
      ],
    }
  },
  computed: {
    ...mapState('client', ['contratos']),
  },
  methods: {
    descargarFactura(idCuenta, i) {
      this.facturas = this.facturas.map(el => {
        const newEl = el
        newEl.variant = false
        return newEl
      })
      this.facturas[i].variant = 'primary'
      const fd = { idCuenta }
      this.sendGetProcedure('/descargaDuplicado', fd).then(res => {
        //  console.log('res', res)
        this.facturas = res.data.list.map(el => ({
          fechaPago: el.fechaPago,
        }))
      })
    },
    confirm() {
      this.sendGetProcedure(this.procedure, this.data).then(res => {
        this.total = res.data.object.totalValue
        this.totalFinancing = res.data.object.totalFinancing
        this.totalBalance = res.data.object.totalBalance
        /* this.items = res.data.object.details.map(el => ({
          nombreServicio: el.serviceDescription,
          CuentasConSaldo: el.balance,
          TotalPendiente: el.totalBalance,
        })) */

        this.items = res.data.object.details.map(el => [
          el.serviceDescription,
          el.balance,
          el.totalBalance,
        ])
        this.detailsFinancing = res.data.object.detailsFinancing.map(el => [
          el.idfinancia,
          el.totalBalance,
        ])
      })
    },
    async sendGetProcedure(procedure, formData) {
      // const data = JSON.stringify(formData)
      return new Promise((resolve, reject) => {
        axios
          .get(`/client${procedure}`, { params: formData })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getContract(id) {
      this.contratos = []
      axios.get(`/client/contracts/${1}/${id}`).then(res => {
        this.contratos = res.data.list
      })
    },
  },
}
</script>

<style></style>
